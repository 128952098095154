'use client';

import React, { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastifyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      {children}
      <ToastContainer
        hideProgressBar
        position='bottom-right'
        autoClose={5000}
        theme={'dark'}
        toastStyle={{
          fontFamily: 'Inter, sans-serif',
          fontSize: 14,
          zIndex: 9999999999,
          fontWeight: 500
          // background: '#000',
          // color: 'white'
        }}
      />
    </>
  );
};
